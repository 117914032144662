import { type ClientLoaderFunctionArgs, useLoaderData } from '@remix-run/react';

import { SubscriptionCancelFirstOffer } from '../components/Product/SubscriptionCancel';

export async function clientLoader(action: ClientLoaderFunctionArgs) {
  const url = new URL(action.request.url);

  return {
    reason: url.searchParams.get('reason'),
  };
}

export function Component() {
  const { reason } = useLoaderData<typeof clientLoader>();

  return <SubscriptionCancelFirstOffer reason={reason} />;
}
